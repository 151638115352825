import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Footer from "./components/Footer";
import Header from './components/Header'
import Home from './components/Home'
import Policy from './components/Policy'
import Terms from "./components/Terms";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import NotFound from "./components/NotFound";

function App() {

  let url = process.env.REACT_APP_WSURL
  
  return (
    <div className="App">      
      <Router>
        <Header />
        <div className="page">
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route exact path="/politica-de-privacidad" element={<Policy />} />
            <Route exact path="/terminos-de-uso" element={<Terms />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </div>
        <Footer />
        <div className="whatsappIcon zoomIn">
          <a href={url} target='_blank'>
            <FontAwesomeIcon icon={faWhatsapp} />
          </a>
        </div>
      </Router>
    </div>
  );
}

export default App;
