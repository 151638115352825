import React from 'react';

const Hero = () => {

  let url = process.env.REACT_APP_WSURL

  const data = [
    {
      'title': 'Servicios Juridicos Contables y Administrativos',
      'desc': 'Te ayudamos a manejar tus temas contables. Jurídicos y administrativos de tu compañía con asesorías, consultarías e implementación de soluciones.',
      'buttonText': 'Agendar Cita',
      'backgroundImage': './images/header.png'
    }
  ]

  return (
    <>
      {data.map((item, i) => (
        <div key={i} className='hero'>
          <div key={i} className='bg-hero' style={{ backgroundImage: `url(${item.backgroundImage})`, backgroundRepeat: 'no-repeat', backgroundPosition: 'center center', backgroundSize: 'cover' }} />
            <div className='content'>
              <h1 className='fadeInDown'>{item.title}</h1>
              <p className='fadeInUp'>{item.desc}</p>
              <a className='heroButton fadeIn' target='_blank' href={url}>{item.buttonText}</a>
          </div>
        </div>
      ))}
    </>
  );
}

export default Hero;
