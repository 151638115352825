import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {

  let domain = process.env.REACT_APP_DOMAIN
  let year = new Date().getFullYear()

  return (
      <div className='footer'>
        <div className='content'>
          <div className='copy'>&copy; {domain} {year}</div>
          <div className='terms'>
            <Link to='/politica-de-privacidad'>Política de Privacidad</Link>          
            <Link to='/terminos-de-uso'>Términos de Uso</Link>
          </div>
        </div>
      </div>
  );
}

export default Footer;
