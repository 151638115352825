import React from 'react';
import Item from './Item';

const Services = () => {

  const data = [
    {
      'urlImage': './images/servicios-contables-servicios-gt.jpg',
      'title': `<h2><span>Servicios</span> contables</h2>`,
      'desc': `<p>Nuestros servicios contables tienen como garantía de calidad que puedas ver tu información en tiempo real, si la preparación de tus Estados Financieros, libros contables y si tus declaraciones de impuestos no se han realizado tu mes de honorarios es gratis.</p>`,
      'id': 'contabilidad',
      'iconSet': [
        {
          'icon': './icons/reporteria-icono.svg',
          'title': `<h4>Libros contables</h4>`,
          'desc': `<p>Diario, mayor, inventarios, compras, ventas</p>`
        },
        {
          'icon': './icons/Libros-contables-icono.svg',
          'title': `<h4>Estados financieros</h4>`,
          'desc': `<p>Estado de situación financiera, estado de resultados, estado de flujo de efectivo, notas a los estados financieros</p>`
        },
        {
          'icon': './icons/tributario-icono.svg',
          'title': `<h4>Declaraciones de impuestos</h4>`,
          'desc': `<p>Dependerá del régimen en el cual estés inscrito</p>`
        }                
      ]
    },
    {
      'urlImage': './images/servicios-de-auditoria-servicios-gt.jpg',
      'title': `<h2><span>Servicios de</span> Auditoría</h2>`,
      'desc': `<p>Obtén informes de profesionales independientes que te mostraran la realidad de la información que te sirve para tomar decisiones, ya no tomes decisiones a ciegas y date la oportunidad de tener un equipo de trabajo que te brindará soluciones eficaces.</p>`,
      'id': 'administrativo',
      'iconSet': [
        {
          'icon': './icons/Legal-icono.svg',
          'title': `<h4>Auditoria legal</h4>`
        },
        {
          'icon': './icons/tributario-icono.svg',
          'title': `<h4>Auditoria financiera</h4>`
        },
        {
          'icon': './icons/Auditoria-administrativa-icono.svg',
          'title': `<h4>Auditoria administrativa</h4>`
        }                
      ]
    },
    {
      'urlImage': './images/asesoria-fiscal.png',
      'title': `<h2><span>Asesoría</span> fiscal</h2>`,
      'desc': `<p>Saber unir la ley, la contabilidad y las finanzas es un arte que materializamos en el cálculo correcto de impuestos a pagar, formando un plan estratégico anual que te permitirá recuperar impuestos, ajustar correctamente el pago de tributos y hacer tu patrimonio más rentable.</p>`,
      'id': 'juridico',
      'iconSet': [
        {
          'icon': './icons/Legal-icono.svg',
          'title': `<h4>Legal</h4>`
        },
        {
          'icon': './icons/civil-icono.svg',
          'title': `<h4>Civil</h4>`
        },
        {
          'icon': './icons/laboral-icono.svg',
          'title': `<h4>Laboral</h4>`
        },
        {
          'icon': './icons/tributario-icono.svg',
          'title': `<h4>Tributario</h4>`
        }        
      ]
    },
    {
      'urlImage': './images/desarrollo-de-sistemas.png',
      'title': `<h2><span>Desarrollo de</span> Sistemas</h2>`,
      'desc': `<p>La tecnología implica que las personas deben innovar para que sus negocios crezcan solos y ahorrar capital, lo que antes hacían una o varias personas ahora lo puede hacer la tecnología de una forma mucho más económica.</p><p>Utilizamos lenguajes que se adaptan a la necesidad de tu empresa y manejamos toda la estructura de Bases de Datos, Backend y Frontend Desarrollamos desde páginas web hasta sistemas complejos que llevan tecnología web y móvil a través del uso de aplicaciones.</p>`,
      'id':'desarrollo',
      'iconSet': [
        {
          'icon': './icons/desarrollo-web-icono.svg',
          'title': `<h4>Desarrollo web</h4>`
        },
        {
          'icon': './icons/desarrollo-mobile-icono.svg',
          'title': `<h4>Desarrollo mobile</h4>`
        }      
      ]
    }
  ]

  return (
    <>
      <div className='services'>
        {data.map((item, i) => (
          <Item key={i} item={item} />
        ))}
      </div>
    </>
  );
}

export default Services;
