import React from 'react';
import FooterBlock from './FooterBlock';
import { Helmet } from 'react-helmet';

const NotFound = () => {

  let website = process.env.REACT_APP_TITLE

  return (
      <>
        <Helmet>
          <html lang="en" />
          <title>Página no encontrada | {website}</title>
          <meta name="description" content="Servicios Juridicos Contables y Administrativos Te ayudamos a manejar tus temas contables. " />
        </Helmet>
        <div className='notFound'>
          <div className='content'>
            <main>
              <header>
                <h1>No se ha podido encontrar la página.</h1>
              </header>
              <div>
                <p>Parece que no se ha encontrado nada en esta ubicación.</p>
              </div>
            </main>
          </div>
          <FooterBlock />
        </div>        
      </>

  );
}

export default NotFound;
