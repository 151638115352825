import React from 'react';
import PolicyText from '../documents/PoliticaText';
import FooterBlock from './FooterBlock';
import { Helmet } from 'react-helmet';

const Policy = () => {

  let website = process.env.REACT_APP_TITLE

  return (
    <>
      <Helmet>
        <html lang="en" />
        <title>Políticas de privacidad | {website}</title>
        <meta name="description" content="Servicios Juridicos Contables y Administrativos Te ayudamos a manejar tus temas contables. " />
      </Helmet>  
      <div className='policy'>
        <PolicyText />
        <FooterBlock />
      </div>
    </>
  );
}

export default Policy;
