import { React, useState, useRef } from 'react';
import useIsInViewport from '../utils/ref';

const FooterBlock = () => {

  let url = process.env.REACT_APP_WSURL

  const [stat, setStat] = useState(false)

  const ref = useRef(null);
  
  const isInViewport1 = useIsInViewport(ref);

  if(isInViewport1 === true && stat === false){
    setStat(true)
  }

  const data = [
    {
        'kicker': `<p>¿Necesita ayuda?</p>`,
        'title': `<h3>¿Tu contabilidad está fuera de tus manos?</h3>`,
        'desc': `<p>No pierdas más tiempo y recursos, contacta a profesionales que te ayuden a tener todo bajo control</p>`,
        'backgroundImage': '/images/footerBlock.png',
        'buttonText': 'Solicitar Servicio'
    }
  ]

  return (
      <div ref={ref} className={stat ? 'fadeInUp footerBlock' : 'no-visible footerBlock'}>
          {data.map((item, i) => (
            <div key={i} className='content' style={{
              backgroundImage: `url(${item.backgroundImage})`,
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center center'
            }}>
              <div>
                <div className='kicker' dangerouslySetInnerHTML={{__html: item.kicker}} />
                <div dangerouslySetInnerHTML={{__html: item.title}} />
                <div dangerouslySetInnerHTML={{__html: item.desc}} />
                <a href={url}  target="_blank" ref={ref} className={stat ? 'fadeIn button' : 'button'}>{item.buttonText}</a>
              </div>
            </div>
          ))}
          <div className='bgGray'></div>
      </div>
  );
}

export default FooterBlock;
