import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import logo from '../logo.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes, faChevronDown } from '@fortawesome/free-solid-svg-icons';

const Header = () => {
  const [expanded, setExpanded] = useState(false);
  const url = process.env.REACT_APP_WSURL;
  let path = '';

  if(window.location.pathname !== '/'){
    path = '/'
  }

  console.log(path)

  const menubar = [
    {
      'url': '#contabilidad',
      'title': 'Contabilidad'
    },
    {
      'url': '#administrativo',
      'title': 'Administrativo'
    },
    {
      'url': '#juridico',
      'title': 'Jurídico'
    }     
  ]

  return (
    <>
      <header className='header'>
        <div className='headerContent'>
          <div className='logo'>
            <Link to='/' className='p-3'>
              <img src={logo} className="App-logo" alt="logo" />
            </Link>
          </div>
          <div className='hamburguer-icon'>
            <div className='icon'>
                <FontAwesomeIcon onClick={() => setExpanded(true)} icon={faBars}></FontAwesomeIcon>
            </div>
          </div>
          <div className={expanded ? 'menu' : 'menu menu-inactive'}>
            <div className='content'>
              <div className='closeIcon'>
                <FontAwesomeIcon icon={faTimes} onClick={() => setExpanded(false)}  />
              </div>
              <div className='menuHeader'>
                <a href='/'>
                  <img src='/images/servicios_gt_imagotipo_blanco.svg' alt='Servicios.gt' />
                </a>
              </div>
              <div className='divider'>
                <span />
              </div>
              <nav>
                {menubar.map((item, i) => (
                  <a key={i} href={path + item.url} onClick={() => setExpanded(false)}>{item.title} <FontAwesomeIcon icon={faChevronDown} className='chevrondown' /></a>
                ))}
                <a href={url} target='_blank' className='buttonNav'>
                  Agendar cita
                </a>
              </nav>
              <div className='divider'>
                <span />
              </div>
            </div>
          </div>
        </div>
      </header>
    </>

  );
}

export default Header;
