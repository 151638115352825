import React, { useRef, useState } from 'react';
import useIsInViewport from '../utils/ref';

const Benefits = () => {

  const [stat, setStat] = useState(false)

  const ref = useRef(null);
  
  const isInViewport1 = useIsInViewport(ref);

  if(isInViewport1 === true && stat === false){
    setStat(true)
  }

  let url = process.env.REACT_APP_WSURL

  const data = [
    {
      'icon': './icons/reporteria-icono.svg',
      'title': `<h4><span>Reportería</span></h4>`,
      'desc': `<p>Tener información para la toma de decisiones (Reportes de Inventarios, Productos, Márgenes de Utilidad, entre otros)</p>`
    },
    {
      'icon': './icons/Facturas-ilimitadas-icono.svg',
      'title': `<h4><span>Facturas ilimitadas</span></h4>`,
      'desc': `<p>Sin límite de emisión</p>`
    },
    {
      'icon': './icons/Contabilidad-automática-icono.svg',
      'title': `<h4><span>Contabilidad automática</span></h4>`,
      'desc': `<p>Generar tu contabilidad, nuestro sistema se conecta con SAT procesando los XML de las operaciones realizadas teniendo en tiempo real tus estados financieros y libros contables.</p>`
    },
    {
      'icon': './icons/Sin-contrato-icono.svg',
      'title': `<h4><span>Sin contrato</span></h4>`,
      'desc': `<p>Contrata y cancela cuando quieras</p>`
    },
    {
      'icon': './icons/Datos-ilimitados-icono.svg',
      'title': `<h4><span>Datos ilimitados</span></h4>`,
      'desc': `<p>Usuario, Sucursales y Equipos ilimitados</p>`
    },

  ]

  return (
    <>
      <div ref={ref} className={stat ? 'benefits fadeInUp' : 'benefits no-visible'} >
        <div className='content'>
          <h3>Beneficios</h3>
          {data.map((item, i) => (
            <div key={i} className='benefit'>
              <div className='icon'>
                <img src={item.icon} />
              </div>            
              <div dangerouslySetInnerHTML={{__html: item.title}} />
              <div dangerouslySetInnerHTML={{__html: item.desc}} />         
            </div>
          ))}
          <a className='benefitsButton' href={url} target="_blank">Solicitar Servicio</a>
        </div>
      </div>
    </>
  );
}

export default Benefits;
