import { React, useRef, useState } from 'react';
import useIsInViewport from '../utils/ref';

const Block = () => {

  const [stat, setStat] = useState(false)

  const ref = useRef(null);
  
  const isInViewport1 = useIsInViewport(ref);

  if(isInViewport1 === true && stat === false){
    setStat(true)
  }

  const data = [
    {
        'title': `<h2><span>Facturación</span><br> electrónica</h2>`,
        'desc': `<p>Por requerimiento legal es obligatorio el uso de Facturas Electrónicas (FEL), utiliza nuestra infraestructura y aprovecha todas las ventajas.</p>`,
        'urlImage': './images/fel-image.png'
    }
  ]

  return (
      <div className='block'>
        {data.map((item, i) => (
          <div key={i} className='blockContent'>
            
            <div className='left'>
              <div ref={ref} className={stat ? 'fadeInUp' : 'no-visible'} dangerouslySetInnerHTML={{__html: item.title}} />
              <div ref={ref} className={stat ? 'fadeInUp' : 'no-visible'} dangerouslySetInnerHTML={{__html: item.desc}} />
            </div>
            <div ref={ref} className={stat ? 'imageBlock zoomIn' : 'imageBlock no-visible'} >
              <img src={item.urlImage} />
            </div>
          </div>
        ))}
      </div>
  );
}

export default Block;
