import React from 'react';

const PolicyText = () => {

  return (
      <div className='inner'>
        <div>
			<div>
				<div>
			        <h1>Política de Privacidad</h1>
                </div>
			</div>
			<div>
            <div>
                <p>
                    <span>Servicios y Bienes Profesionales está compuesto por varios sitios web de carácter global, nacional, regional o de prácticas específicas proveídas por Servicios y Bienes Profesionales o alguna de sus firmas miembro. Cada uno de estos sitios específicos está designado en la esquina superior derecha de cada página web</span></p>
                    <p>&nbsp;</p>
                    <p><span>Este Aviso de Privacidad aplica sólo a la página específica de servicios.gt designada como Guatemala en la esquina superior derecha, que se denominará de ahora en adelante como «este Sitio Web».</span></p><p>&nbsp;</p>
                    <p><span>Servicios y Bienes Profesionales en Guatemala es una firma afiliada a la marca Servicios y Bienes Profesionales de Servicios y Bienes Profesionales, la cual es una firma miembro de Servicios y Bienes Profesionales (también denominado de ahora en adelante «nosotros» o «nuestro») es una organización global privada.&nbsp;</span></p>
                    <p><span>Somos la entidad dentro de la Red Servicios y Bienes Profesionales que provee este Sitio Web y esta declaración de privacidad explica cómo protegemos la información recopilada de los visitantes a través de este Sitio Web.</span></p><p>&nbsp;</p>
                    <p><b>Al utilizar este sitio web, usted acepta el uso de su información como se describe en este Aviso de Privacidad.</b></p><p>&nbsp;</p><p><span>Por favor, tenga en cuenta que los sitios web específicos del otro país, regional y de práctica contenidos en servicios.gt son proporcionados por otras entidades dentro de la Red Servicios y Bienes Profesionales y no son proporcionados por nosotros.&nbsp;</span></p>
                    <p><span>Estos sitios web, así como otros sitios web que pueden estar vinculados a este Sitio Web, no se rigen por este Aviso de Privacidad. Invitamos a los visitantes a revisar cada uno de los Avisos de Privacidad de esos otros sitios web antes de divulgar cualquier información personal.</span></p><p>&nbsp;</p>
                    <p><span>Este Aviso de Privacidad puede ser completado o modificado de vez en cuando por los Avisos de Publicidad específicos sobre ciertas áreas de este Sitio Web (por ejemplo, la contratación). Para obtener más detalles sobre el proceso de contratación en línea, por favor revise la declaración específica que regule esa área.</span></p><p>&nbsp;</p>
                    <p><span>Si usted tiene alguna pregunta sobre este Aviso de Privacidad, por favor póngase en contacto con el webmaster a través de la página Contáctenos</span></p>
                </div>
			</div>
			<div>
				<div>
			        <h2>Recopilación de la información</h2>
                </div>
			</div>
				<div>
				<div>
				    <p><span>Como visitante, usted no tiene que proporcionar información personal para utilizar el Sitio Web. Este Sitio Web sólo recopila información personal que es específica y voluntariamente proporcionada por los visitantes. Dicha información puede consistir en, pero sin limitarse a, su nombre, cargo actual, la dirección de la empresa, dirección de correo electrónico y números de teléfono y fax, así como la información que se proporcione de forma voluntaria según los servicios prestados por Servicios y Bienes Profesionales o las firmas miembro de la Red de Servicios y Bienes Profesionales como se establece en los términos de uso y los servicios o productos asociados a la marca Servicios y Bienes Profesionales.</span></p><p>&nbsp;</p><p><span>También podemos almacenar y mantener cualquier contenido que usted proporcione incluyendo, pero sin limitarse a, publicaciones en blogs, foros, wikis y otras aplicaciones y servicios que pueden proporcionar las redes sociales.</span></p><p>&nbsp;</p><p><span>Nosotros generalmente no solicitamos información sensible (por ejemplo, los datos relacionados con la raza o el origen étnico, las creencias religiosas, salud física, mental, o la orientación sexual) de los visitantes. Nosotros, en caso necesario, obtendremos su consentimiento expreso para recoger y utilizar dicha información.</span></p>						</div>
				</div>
				<div>
				<div>
			<h2>Información de registro, cookies y web beacons
</h2>		</div>
				</div>
				<div>
				<div>
					<p><span>Este sitio recoge información estándar de registro, incluyendo su dirección IP, tipo de navegador, el idioma, tiempos de acceso y direcciones de sitios web. Para asegurar que este Sitio Web está siendo bien administrado y facilitar una mejor navegación, nosotros o nuestros proveedores de servicios podemos utilizar cookies (pequeños archivos de texto que se almacenan en el navegador del usuario) o web beacons (imágenes electrónicas que permiten contar los visitantes que han accedido a una página en particular y acceder a determinadas cookies) para recoger datos agregados. Para información adicional sobre cómo utilizamos cookies y otras tecnologías de seguimiento y cómo se puede controlar, pueden revisar nuestro Aviso de uso de Cookies.</span></p>
<p></p>
<h3><b>Uso de la información</b></h3>
<p><span>A veces, usted puede entregar información personal a través de este sitio. Por ejemplo: para acceder a determinados contenidos, asistir a un evento organizado, responder a una encuesta o solicitar comunicaciones sobre áreas específicas de interés. En tales casos, la información que envíe se utilizará para gestionar su solicitud, para personalizar y mejorar este Sitio Web y los servicios relacionados. También podemos utilizar su información personal para fines de marketing o para enviarle materiales promocionales o comunicaciones relativos a los servicios prestados por las entidades dentro de la Red Servicios y Bienes Profesionales, que consideremos puedan ser de su interés. También podemos comunicarnos con usted para recabar la opinión sobre los servicios prestados por las entidades de la Red Servicios y Bienes Profesionales o para otros fines de investigación o de mercado.</span></p>
<p></p>
<p><span>Su información personal también puede ser usada para proteger nuestros derechos o propiedad, así como la de nuestros usuarios y, en su caso, en el cumplimiento de un proceso legal.</span></p>
<p></p>
<p><span>Usted puede solicitar en cualquier momento que dejemos de enviarte correos electrónicos u otras comunicaciones que se generan en respuesta a su registro en este Sitio Web</span></p>
<p></p>
<p><br/></p>
<p></p>
<h3><b>Divulgación de Información a terceros</b></h3>
<p><span>Podemos proporcionar su información personal a otras entidades dentro de la Red Servicios y Bienes Profesionales, a fin de proporcionarle la información que podría ser de interés para usted y realizar otras investigaciones de mercado. La información personal también puede ser revelada a otras entidades dentro de la Red Servicios y Bienes Profesionales y otras terceras partes, a fin de responder a sus solicitudes o preguntas, como parte de una transacción corporativa, tales como la venta, cesión, reorganización, fusión o adquisición, o cuando estas partes manejen la información en nuestro nombre.</span></p>
<p></p>
<p><span>Todas estas revelaciones pueden implicar la transferencia de datos personales a países o regiones sin normas de protección de datos similares a los vigentes en su área de residencia.</span></p>
<p></p>
<p><span>La información personal también puede ser revelada a las autoridades policiales, las agencias gubernamentales reguladoras o de otro tipo, o para terceros, en cada caso, para cumplir con obligaciones, solicitudes legales o reglamentarias, todo ello cumpliendo los procedimientos legales en la jurisdicción que corresponda.</span></p>
<p></p>
<p><span>Al proporcionar información a través de este Sitio Web, usted acepta las divulgaciones descritas arriba.</span></p>
<p></p>
<p><span>&nbsp;</span></p>
<p></p>
<h3><b>Blogs, foros, wikis y otras redes sociales</b></h3>
<p><span>Este Sitio Web puede albergar varios blogs, foros, wikis y otras aplicaciones o servicios que permiten compartir contenidos con otros usuarios (colectivamente: “Aplicaciones para redes sociales”). Cualquier información personal o cualquier otra información que usted comparta a través de una aplicación para redes sociales pueden ser leída, recopilada y utilizada por otros usuarios de esa aplicación, sobre los que tenemos poco o ningún control. Por lo tanto, no nos hacemos responsables del uso que pueda hacer cualquier otro usuario, así como el mal uso o apropiación indebida de cualquier información personal u otra información, que usted aporte a cualquier aplicación para redes sociales.</span></p>
<p></p>
<p><span>&nbsp;</span></p>
<p></p>
<p><b>Acceso a la información</b></p>
<p><span>Los visitantes que necesiten alguna copia de su información, actualizar sus detalles o darse de baja en la recepción de noticias deberán contactar al webmaster, accediendo a la página Contáctenos.&nbsp; En todos los casos trataremos las solicitudes de acceso a la información o cambiar la información de acuerdo con los requisitos legales aplicables.&nbsp;&nbsp;&nbsp;</span></p>
<p></p>
<p><span>&nbsp;</span></p>
<p></p>
<p><b>Seguridad de la Información&nbsp;</b></p>
<p><span>Tenemos implementados en este Sitio Web estándares comerciales de tecnología y seguridad operacional para proteger a nuestros visitantes de accesos no autorizados, revelación, alteración o destrucción de toda la información proporcionada.</span></p>
<p></p>
<p><span>&nbsp;</span></p>
<p></p>
<p><b>Cambios a nuestro Aviso de Privacidad</b></p>
<p><span>Podremos modificar o enmendar este Aviso de Privacidad de vez en cuando, de forma discrecional y unilateral. Cuando hagamos cambios a este, se actualizará la fecha de revisión en la parte superior de esta página, y esa modificación o enmienda será efectiva para usted y su información a partir de la fecha de actualización. Le recomendamos que revise periódicamente este Aviso de Privacidad para estar informado acerca de cómo estamos protegiendo su información.</span></p>
<p></p>
<p><span>&nbsp;</span></p>
<p></p>
<p><b>Protección a la privacidad de los menores de edad</b></p>
<p><span>Somos conscientes de la importancia de proteger la privacidad de los menores de edad. Este Sitio Web no está diseñado ni está intencionalmente dirigido a menores de 15 años de edad o más jóvenes. No es nuestra política recolectar o mantener información de cualquier persona menor de 15 años.</span></p>
<p></p>
<p><span>&nbsp;</span></p>
<p></p>
<p><b>Preguntas de los visitantes</b></p>
<p><span>Si usted tiene alguna pregunta o inquietud con respecto a su privacidad durante el uso de este Sitio Web, por favor contacte los servicios del webmaster en la página Contáctenos.</span></p>						</div>
			</div>
		</div>        
      </div>
  );
}

export default PolicyText;
