import React from 'react';
import Hero from './Hero';
import Block from './Block';
import Benefits from './Benefits';
import Services from './Services';
import FooterBlock from './FooterBlock';
import { Helmet } from 'react-helmet';

const Home = () => {

  let website = process.env.REACT_APP_TITLE

  return (
    <>
      <Helmet>
        <html lang="en" />
        <title>Home | {website}</title>
        <meta name="description" content="Servicios Juridicos Contables y Administrativos Te ayudamos a manejar tus temas contables. " />
      </Helmet>     
      <div className='home'>
        <Hero />
        <Block />
        <Benefits />
        <Services />
        <FooterBlock />
      </div>
    </>
  );
}

export default Home;
