import { React, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import useIsInViewport from '../utils/ref';

const Item = ( props ) => {

    let { item } = props;

    let url = process.env.REACT_APP_WSURL

    const [stat, setStat] = useState(false)

    const ref = useRef(null);
    
    const isInViewport1 = useIsInViewport(ref);
  
    if(isInViewport1 === true && stat === false){
      setStat(true)
    }

    return (
        <div className='service columns' id={item.id}>
            <div className='left'>
                <div ref={ref} className={stat ? 'zoomIn bgImage' : 'no-visible bgImage'} style={{ backgroundImage: `url(${item.urlImage})`, backgroundSize: 'cover' }} />
            </div>
            <div className='right'>
                <div ref={ref} className={stat ? 'fadeInUp' : 'no-visible'} dangerouslySetInnerHTML={{__html: item.title}} />
                <div ref={ref} className={stat ? 'fadeInUp' : 'no-visible'} dangerouslySetInnerHTML={{__html: item.desc}} />
                <>
                    <div className='benefits'>
                    <div ref={ref} className={stat ? 'fadeInUp content' : 'no-visible content'} >
                        <h3>Beneficios</h3>
                        {item.iconSet.map((icon, j) => (
                        <div key={j} className='benefit'>
                            <div className='icon'>
                            <img src={icon.icon} />
                            </div>
                            <div dangerouslySetInnerHTML={{__html: icon.title}} />
                            <div dangerouslySetInnerHTML={{__html: icon.desc}} />
                        </div>
                        ))}
                        <a className='benefitsButton' target='_blank' href={url}>Solicitar Servicio</a>
                    </div>
                    </div>
                </>
            </div>
        </div>        
    )
}

export default Item